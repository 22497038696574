////
/// Header
////

body {
  margin: 0;
}

.site-header {
  position: relative;
  overflow: hidden;
  padding: 0.5rem 0 0;
  min-height: 35vmin;
  .wrapper {
    display: flex;
    align-content: center;
    margin-bottom: 0;
    height: 20vmin;
  }
}

.site-logo {
  display: flex;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    padding: 0 $base-line/2 $base-line;
    background-color: transparent;
    max-width: 300px;
  }
}

.nav-main {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 0.2rem;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;

  }
  li {
    margin-right: 0.3rem;
    text-align: center;
  }
  a {
    display: block;
    padding: 0.5rem 1.5rem;
    font-weight: bold;
    color: #fff;
    text-shadow: 1px 1px rgba(0,0,0,0.8);
    background: rgba(0,0,0,0.5);
  }
}

.illustration-header {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  img {
    object-fit: cover;
    width:100%;
    height: 100%;
  }
}
