////
/// Media queries
////

// Media Query Ranges
//-------------------------------------

$tiny-range                 : (0em, 30em) !default;
$small-range                : (0em, 48em) !default;
$medium-range               : (48.063em, 64em) !default;
$large-range                : (64.063em, 90em) !default;
$xlarge-range               : (90.063em, 120em) !default;
$xxlarge-range              : (120.063em, 99999999em) !default;

// Media Query breakpoint
//-------------------------------------

$screen:                    "only screen";
$small:                     0;
$tiny:                      30.063em;  /*480px */
$medium:                    48.063em;  /*768px */
$large:                     64.063em;  /*1025px*/
$xlarge:                    90.063em;  /*1441px*/
$xxlarge:                   120em;     /*1921px*/


// Media Query Ranges :: Mixin
//-------------------------------------

// functions

@function lower-bound($range){
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range,1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// Call functions and declare media range variable

$landscape    : "#{$screen} and (orientation: landscape)";
$portrait     : "#{$screen} and (orientation: portrait)";
$small-up     : $screen;
$tiny-only    : "#{$screen} and (max-width:#{upper-bound($tiny-range)})";
$small-only   : "#{$screen} and (max-width:#{upper-bound($small-range)})";
$medium-up    : "#{$screen} and (min-width:#{lower-bound($medium-range)})";
$medium-only  : "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";
$large-up     : "#{$screen} and (min-width:#{lower-bound($large-range)})";
$large-only   : "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";
$xlarge-up    : "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xlarge-only  : "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";
$xxlarge-up   : "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";
$xxlarge-only : "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";


// Unison
// * speaking with JS with unison.js
//-------------------------------------

// config
$debug: false;

// Unison media
$mq-sync:
usn-small           $small,
usn-medium          $medium,
usn-large           $large
;
// usn-large          $large,
// usn-x-large        $xlarge,
// usn-xx-large       $xxlarge

// build each media query for js ingestion
@each $mq in $mq-sync {
  @media screen and (min-width: nth($mq, 2)) {
    head { font-family: "#{nth($mq, 1)} #{nth($mq, 2)}"; }
    body:after { content: "#{nth($mq, 1)} - min-width: #{nth($mq, 2)}"; }
  }
}
head {
// set clear on head to show Unison is set up correctly
clear: both;
// store hash of all breakpoints
title { font-family: "#{$mq-sync}"; }
}
// debug styles to see breakpoint info
body:after {
  display: none;
}
// hide elements for conditional loading
// only used for responsive comments plugin
*[data-usn-if] { display: none; }


