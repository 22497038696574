////
/// Members
////

.search {
  input {
    margin-bottom: $base-line;
    padding: 0.5rem 0.4rem;
    width: 100%;
    font-size: $bigger;
    border: 1px solid #888;
  }
}

.map-link {
  a {
    &:hover {
      text-decoration: none;
      border-bottom: 1px soldi #000;
    }
  }
}

.members-list {
  margin-top: $base-line*2;
  width: 100%;
  @include grid(2);
}

.members-list__box {
  overflow: hidden;
  display: flex;
  align-items: center; 
  flex-direction: column;
  margin-bottom: $base-line;
  border: 1px solid palette(primary);
  border-radius: 2px;
  box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
}

.members-list__line {
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  &.information {
    padding: $base-line;
    align-items: center;
  }
  &:last-child {
    padding: 0;
  }
}

.members-list__item {
  flex: 1;
  &.location {
    float: right;
    padding: $base-line/2 $base-line;
    max-width: 14rem;
    text-align: right;
    color: #fff;
    background: palette(primary);
    border-radius: 0 0 0 25px;
  }
  &.name {
    flex: 2;
    padding: $base-line/3 $base-line;
    font-size: $big;
  }
  &.address {
    padding: $base-line/3 $base-line;
    max-width: 22rem;
  }
  &.téléphone-mobile {
      padding: 0 1.2em 1em;
    span:not(:first-child) {
      &:before {
        content: ' / '
      }
    }
  }
  &.email {
    text-align: center;
  }
  &.activity {
    //display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: $base-line/2;
    width: 100%;
    font-size: 0.9rem;
    border-top: 1px solid palette(primary);
  }
}

.members-list__item__activity {
  display: inline-block;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  color: palette(primary,dark);
  &:after {
    content: ' - ';
  }
  &:last-child:after {
    content: '';
  }
}
