@charset "utf-8";

// Import partials from `sass_dir` (defaults to `_sass`)
@import "variables";
@import "layout";
@import "generic/normalize-opentype";
@import "object/header";
@import "page/activities";
@import "page/members";
@import "page/profiles";
@import "tool/utilities";


.jumbo {
  text-align: center;
  overflow: hidden;
  border: 1px solid palette(primary, light);
  margin: 1em;
  padding: 0 $base-line $base-line;
}