////
/// Layout
////

$gutter: $base-em;
$number: 4; // for equal columns

/**
 * Wrapper
 */

.wrapper {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: $base-line*2;
  padding-right: $base-line / 2;
  padding-left: $base-line / 2;
  max-width: 65rem;
}

// grid styles for container wich has a .grid(n,g) class
// n = number of columns (default = 4)
// g = gutter value (default = 1em)
// example : .grid-container { .grid(12, 10px); }

@mixin grid($number:$number, $gutter:$gutter) {
  margin-bottom: $base-line;
  & > * {
    margin-bottom: $base-line;
  }
  @media #{$medium-up} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -$gutter;

    /* inline-block fallback for IE9 generation */
    letter-spacing: -0.31em;
    text-rendering: optimizespeed;
    & > * {
      flex: 0 0 auto;
      //width: calc(100% * 1 / $number - $gutter - .01px);
      width: calc(100% * 1 / #{$number} - #{$gutter} - .01px);
      margin-left: $gutter;

      /* inline-block fallback for IE9 generation */
      display: inline-block;
      vertical-align: top;
      letter-spacing: normal;
      text-rendering: auto;
    }
    & > .flexitem-double {
      width: calc(100% * 2 / #{$number} - #{$gutter});
    }
    & > .flexitem-first {
      order: -1;
    }
  }
}

