////
/// Activities
////

.nav-activity {
  ul {
    margin: 0 0 $base-line;
    padding: 0;
    list-style-type: none;
  }
  li {
    display: inline-block;
    margin: 0 0 $base-line;
  }
  a {
    padding: 0.2rem 0.5rem;
    color: #fff;
    background: palette(primary);
    border-radius: 4px;
  }
}

//https://www.trysmudford.com/blog/fade-out-siblings-css-trick/
.activity-list {
  @include grid(3);
  pointer-events: none;
  &:hover > *,
  &:focus-within > * {
    opacity: 0.5;
  }
  &:hover > *:hover,
  &:focus-within > :focus {
    transform: scale(1.03);
    opacity: 1;
  }
  & > * {
    pointer-events: auto;
    transition: 300ms opacity, 300ms transform;
  }
}

.activity-list__box {
  overflow: hidden;
  border: 1px solid palette(primary);
  border-radius: 4px;
  box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
  @media #{$medium-up} {
    min-height: 18rem;
  }
  > * {
    padding: 0 $base-line 0;
  }
  h3 {
    min-height: 68px;
    display: flex;
    align-items: center;
    margin: 0 0 $base-line;
    padding: $base-line;
    font-size: 1em;
    background: $brand-color;
    &, a {
      color: #fff;
    }
  }
  p{
    font-weight: 500;
  }
}
