////
/// Colors
////

// better color for development
//-------------------------------------

$navy:                    #001F3F;
$blue:                    #0074D9;
$aqua:                    #7FDBFF;
$teal:                    #39CCCC;
$olive:                   #3D9970;
$green:                   #2ECC40;
$lime:                    #01FF70;
$yellow:                  #FFDC00;
$orange:                  #FF851B;
$red:                     #FF4136;
$fuchsia:                 #F012BE;
$purple:                  #B10DC9;
$maroon:                  #85144B;

$text-color:              #111;
$background-color:        #fdfdfd;
$brand-color:             #cf837c;
$color-primary:           $brand-color;
$color-secondary:         #064b3b;

$grey-color:              #828282;
$grey-color-light:        lighten($grey-color, 40%);
$grey-color-dark:         darken($grey-color, 25%);


// Déclaration de la palette de couleur
//-------------------------------------

$palettes: (
    primary: (
        base:   $color-primary,
        light:  mix(white, $color-primary, 30%),
        dark:   mix(black, $color-primary, 30%)
    ),
    secondary: (
        base:   #ffa900,
        light:  mix(white, $color-secondary, 30%),
        dark:   mix(black, $color-secondary, 30%)
    ),
    grey: (
        base:   #595959,
        light:  rgba(0,0,0,0.12),
        dark:   #353535
    )
);

// Mixin d'appel de la palette couleur
//-------------------------------------

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}
